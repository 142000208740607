import axios from "axios";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { HistoricalChart } from "../@types/stables";
import appStore from "../app/app.store";
import SelectButton from "./SelectButton";
import { makeStyles } from "tss-react/mui";
import { CircularProgress } from "@mui/material";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const chartDays = [
  {
    label: "24 Hours",
    value: 1,
  },
  {
    label: "30 Days",
    value: 30,
  },
  {
    label: "3 Months",
    value: 90,
  },
  {
    label: "1 Year",
    value: 365,
  },
];

const CoinInfo = ({ coin }: any) => {
  const [historicData, setHistoricData] = useState<any[]>();
  const [days, setDays] = useState(1);
  const [flag, setflag] = useState(false);

  const useStyles = makeStyles()((theme) => ({
    container: {
      width: "75%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 25,
      padding: 40,
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginTop: 0,
        padding: 20,
        paddingTop: 0,
      },
    },
  }));

  const { classes } = useStyles();

  const fetchHistoricData = async () => {
    const { data } = await axios.get(
      HistoricalChart(coin.id, days, appStore.currency)
    );
    setflag(true);
    setHistoricData(data.prices);
  };

  console.log(historicData);
  console.log(coin);

  useEffect(() => {
    fetchHistoricData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  return (
    <div className={classes.container}>
      {!historicData || flag === false ? (
        <CircularProgress
          style={{ color: "var(--primary)" }}
          size={250}
          thickness={1}
        />
      ) : (
        <>
          <Line
            data={{
              labels: historicData?.map((coin) => {
                let date = new Date(coin[0]);
                let time =
                  date.getHours() > 12
                    ? `${date.getHours() - 12}:${date.getMinutes()} PM`
                    : `${date.getHours()}:${date.getMinutes()} AM`;
                return days === 1 ? time : date.toLocaleDateString();
              }),

              datasets: [
                {
                  data: historicData?.map((coin) => coin[1]),
                  label: `Price ( Past ${days} Days ) in ${appStore.currency}`,

                  borderColor: "#17c4e6",
                },
              ],
            }}
            options={{
              elements: {
                point: {
                  radius: 1,
                  borderColor: "#17c4e6",
                },
              },
            }}
          />
          <div
            style={{
              display: "flex",
              marginTop: 20,
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {chartDays.map((day) => (
              <SelectButton
                key={day.value}
                onClick={() => {
                  setDays(day.value);
                  setflag(false);
                }}
                selected={day.value === days}
              >
                {day.label}
              </SelectButton>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(CoinInfo);
