import React from "react";
import CoinsDataTable from "../components/CoinsDataTable";
import HeroCarousel from "../components/HeroCarousel";

function HomePage() {
  return (
    <div>
      <HeroCarousel />
      <CoinsDataTable />
    </div>
  );
}

export default HomePage;
