import {
  AppBar,
  Container,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useNavigate } from "react-router-dom";
import appStore from "../app/app.store";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles()((theme) => ({
  title: {
    flex: 1,
    color: "var(--primary)",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

function Header() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <AppBar
      color="transparent"
      position="sticky"
      style={{ backgroundColor: "var(--bg-dark)" }}
    >
      <Container>
        <Toolbar>
          <Typography
            onClick={() => navigate("/")}
            variant="h6"
            className={classes.title}
          >
            OuCrypto
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
          <Select
            variant="outlined"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={appStore.currency}
            style={{ width: 100, height: 40, marginLeft: 15 }}
            onChange={(e) => {
              console.log(e.target.value);
              appStore.setCurrency(e.target.value);
            }}
          >
            <MenuItem value={"USD"}>USD</MenuItem>
            <MenuItem value={"EUR"}>EUR</MenuItem>
          </Select>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default observer(Header);
