import Carousel from "./Carousel";
import { makeStyles } from "tss-react/mui";
import { Container, Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  heroCarousel: {
    backgroundImage: "url(./wp4678541.jpg)",
  },
  heroCarouselContent: {
    height: 400,
    display: "flex",
    flexDirection: "column",
    paddingTop: 25,
    justifyContent: "space-around",
  },
  tagline: {
    display: "flex",
    height: "40%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  carousel: {
    height: "50%",
    display: "flex",
    alignItems: "center",
  },
}));

function HeroCarousel() {
  const { classes } = useStyles();

  return (
    <div className={classes.heroCarousel}>
      <Container className={classes.heroCarouselContent}>
        <div className={classes.tagline}>
          <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              marginBottom: 15,
              fontFamily: "Montserrat",
            }}
          >
            OuCrypto
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              color: "darkgrey",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
            }}
          >
            Obtain comprehensive information about your preferred
            cryptocurrency.
          </Typography>
        </div>
        <Carousel />
      </Container>
    </div>
  );
}

export default HeroCarousel;
